import React, { useState, useEffect, useContext } from "react";
import UserContext from "../UserContext";
import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from "@mui/material";
import styles from "./MinhaCarteira.module.css";

const MinhaCarteira = () => {
	const [empreendimentos, setEmpreendimentos] = useState([]);
	const [redirecionados, setRedirecionados] = useState([]);
	const { user } = useContext(UserContext);
	const API_URL = process.env.REACT_APP_API_URL;

	//console.log("Usuário do contexto em MinhaCarteira:", user);

	useEffect(() => {
		if (user) {
			// Rota para pegar empreendimentos originais
			fetch(`${API_URL}/minha-carteira/redirecionamentos/${user.id_func}`)
				.then((response) => response.json())
				.then((data) => {
					console.log("Data de empreendimentos originais:", data);
					setEmpreendimentos(data.condominios); // Ajuste aqui
				})
				.catch((error) =>
					console.error("Erro ao buscar empreendimentos originais:", error)
				);

			// Rota para pegar empreendimentos redirecionados
			fetch(`${API_URL}/minha-carteira/empreendimentos/${user.id_func}`)
				.then((response) => response.json())
				.then((data) => {
					//console.log("Data de empreendimentos redirecionados:", data);
					setRedirecionados(data); // Ajuste aqui
				})
				.catch((error) =>
					console.error("Erro ao buscar empreendimentos redirecionados:", error)
				);
		}
	}, [user]);

	return (
		<div className={styles.minhaCarteiraContainer}>
			<div className={styles.minhaCarteiraContent}>
				<div className={styles.minhaCarteiraTitle}>Minha Carteira</div>
				<div className={styles.minhaCarteiraTabela}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Empreendimentos</TableCell>
								<TableCell>Peso</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{empreendimentos.map((item, index) => (
								<TableRow key={index}>
									<TableCell>{item.CONDOMINIO}</TableCell>
									<TableCell>{item.peso || 1}</TableCell>
								</TableRow>
							))}

							{redirecionados && redirecionados.length > 0 && (
								<TableRow>
									<TableCell>Redirecionados</TableCell>
									<TableCell>Peso Redirecionado</TableCell>
								</TableRow>
							)}

							{redirecionados &&
								redirecionados.map((item, index) => (
									<TableRow key={index}>
										<TableCell>{item.CONDOMINIO}</TableCell>
										<TableCell>{item.peso || 1}</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</div>
			</div>
		</div>
	);
};

export default MinhaCarteira;
