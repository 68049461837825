import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  ListSubheader,
  FormControl,
} from "@mui/material";
import UserContext from "../UserContext";
import styles from "./AlterarUsuario.module.css"; // Corrigido o caminho do CSS Module
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import PasswordChecker from "../PasswordChecker";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const AlterarUsuario = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const [userOptions, setUserOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editableUserData, setEditableUserData] = useState({
    id_func: "",
    nome: "",
    user: "",
    senha: "",
    area: [],
    funcao: "",
    usuario_cidade: "",
    advogado_externo: "",
    email: "",
    cidade_sede: "",
    telefone: "",
    dt_nascimento: "",
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const schema = yup.object().shape({
    id_func: yup.number().required("Campo obrigatório"),
    nome: yup.string().required("Campo obrigatório"),
    user: yup.string().required("Campo obrigatório"),
    email: yup.string().email("Email inválido"),
    cidade_sede: yup.string(),
    telefone: yup.string(),
    dt_nascimento: yup
      .date()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
    senha: yup
      .string()
      .test(
        "password",
        "A senha deve ter pelo menos 7 caracteres, 1 letra maiúscula, 1 número e 1 caractere especial.",
        (value) => {
          if (!value) return true;
          return /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9]).{7,}$/.test(value);
        }
      ),
    area: yup
      .array()
      .min(1, "Selecione ao menos uma área")
      .required("Campo obrigatório"),
    funcao: yup.string().required("Campo obrigatório"),
    usuario_cidade: yup.string().required("Campo obrigatório"),
    advogado_externo: yup.string().required("Campo obrigatório"),
  });

  useEffect(() => {
    loadAllUsers();
  }, []);

  const loadAllUsers = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/alterar/search-users-by-name`
      );
      setUserOptions(response.data);
    } catch (error) {
      console.error("Erro ao carregar todos os usuários:", error);
    }
  };

  // Agrupar e ordenar usuários
  const groupedUserOptions = userOptions.reduce((acc, user) => {
    const group = user.cidade_sede || "Sem cidade sede";
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(user);
    return acc;
  }, {});

  // Ordenar os usuários dentro de cada grupo
  Object.keys(groupedUserOptions).forEach((key) => {
    groupedUserOptions[key].sort((a, b) => a.nome.localeCompare(b.nome));
  });

  const handleUserSelect = async (event) => {
    const { value } = event.target;
    setSelectedUser(value);
    if (value) {
      try {
        const response = await axios.get(
          `${API_URL}/alterar/get-user/${value}`
        );
        if (response.data && response.data.user) {
          const user = response.data.user;

          if (typeof user.area === "string") {
            user.area = user.area.split(",").map((s) => s.trim());
          } else if (!Array.isArray(user.area)) {
            user.area = [];
          }

          const formatDate = (date) => {
            const d = new Date(date);
            const day = String(d.getDate()).padStart(2, "0");
            const month = String(d.getMonth() + 1).padStart(2, "0");
            const year = d.getFullYear();
            return `${year}-${month}-${day}`;
          };

          setEditableUserData({
            ...user,
            cidade_sede: user.cidade_sede || "",
            telefone: user.telefone || "",
            dt_nascimento: user.dt_nascimento
              ? formatDate(user.dt_nascimento)
              : "",
          });
        } else {
          setErrors((prev) => ({ ...prev, global: "Usuário não encontrado." }));
        }
      } catch (error) {
        console.error("Erro ao buscar usuário:", error);
        setErrors((prev) => ({ ...prev, global: "Erro ao buscar usuário." }));
      }
    } else {
      setEditableUserData({
        id_func: "",
        nome: "",
        user: "",
        senha: "",
        area: [],
        funcao: "",
        usuario_cidade: "",
        advogado_externo: "",
        email: "",
        cidade_sede: "",
        telefone: "",
        dt_nascimento: "",
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setEditableUserData((prevEditableUserData) => {
        const newAreas = [...prevEditableUserData.area];
        if (checked && !newAreas.includes(value)) {
          newAreas.push(value);
        } else {
          const index = newAreas.indexOf(value);
          if (index > -1) {
            newAreas.splice(index, 1);
          }
        }
        return { ...prevEditableUserData, area: newAreas };
      });
    } else {
      setEditableUserData({ ...editableUserData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await schema.validate(editableUserData, { abortEarly: false });

      if (!selectedUser) {
        setErrors((prev) => ({ ...prev, global: "Selecione um Usuário." }));
        return;
      }

      // Remover campos vazios do objeto updatedUserData
      const updatedUserData = Object.keys(editableUserData)
        .filter((key) => editableUserData[key] !== "")
        .reduce((obj, key) => {
          obj[key] = editableUserData[key];
          return obj;
        }, {});

      // Converter o array de áreas em uma string
      if (updatedUserData.area) {
        updatedUserData.area = updatedUserData.area.join(",");
      }

      try {
        await axios.put(
          `${API_URL}/alterar/update-created-user/${selectedUser}`,
          updatedUserData
        );
        toast.success(`Usuário ${editableUserData.user} editado com sucesso!`);
      } catch (error) {
        console.error("Erro ao atualizar dados do usuário:", error);
        setErrors((prev) => ({
          ...prev,
          global: "Erro ao atualizar dados do usuário.",
        }));
        toast.error("Erro ao atualizar dados do usuário.");
      }
    } catch (validationErrors) {
      const formattedErrors = {};
      validationErrors.inner.forEach((error) => {
        formattedErrors[error.path] = error.message;
      });
      setErrors(formattedErrors);
    }
  };

  const handleAreaClick = (areaName) => {
    setEditableUserData((prevEditableUserData) => {
      const newAreas = [...prevEditableUserData.area];
      const index = newAreas.indexOf(areaName);
      if (index > -1) {
        newAreas.splice(index, 1); // Remove a área se já estiver selecionada
      } else {
        newAreas.push(areaName); // Adiciona a área se não estiver selecionada
      }
      return { ...prevEditableUserData, area: newAreas };
    });
  };

  return (
    <div className={styles.scrollContainer}>
      <div className={styles.alterarUsuarioContainer}>
        <form className={styles.alterarUsuarioForm} onSubmit={handleSubmit}>
          <div className={styles.alterarUsuarioDiv1}>
            <h2 className={styles.alterarUsuarioTitle}>
              Alterar dados de um Usuário
            </h2>
          </div>

          <div className={styles.alterarUsuarioDiv2}>
            <FormControl className={styles.formControl}>
              <InputLabel htmlFor="grouped-select">Usuário</InputLabel>
              <Select
                value={selectedUser || ""}
                onChange={handleUserSelect}
                id="grouped-select"
                label="Usuário"
                className={styles.dropdownSelect}
              >
                <MenuItem value="">
                  <em>Nenhum</em>
                </MenuItem>
                {Object.keys(groupedUserOptions)
                  .sort((a, b) => {
                    if (a === "Maringá") return -1;
                    if (b === "Maringá") return 1;
                    if (a === "Sem cidade sede") return 1;
                    if (b === "Sem cidade sede") return -1;
                    return a.localeCompare(b);
                  })
                  .map((city) => [
                    <ListSubheader key={city}>{city}</ListSubheader>,
                    ...groupedUserOptions[city].map((user) => (
                      <MenuItem key={user.id_func} value={user.id_func}>
                        {user.nome}
                      </MenuItem>
                    )),
                  ])}
              </Select>
            </FormControl>
          </div>

          {errors.global && (
            <p className={styles.alterarUsuarioError}>{errors.global}</p>
          )}

          {editableUserData && selectedUser && (
            <>
              <div className={styles.alterarUsuarioDiv3}>
                <div className={styles.alterarUsuarioGrid}>
                  <div className={styles.inputGroup}>
                    <label
                      className={styles.alterarUsuarioLabel}
                      htmlFor="nome"
                    >
                      Nome
                    </label>
                    <input
                      name="nome"
                      value={editableUserData.nome || ""}
                      onChange={handleInputChange}
                      className={styles.alterarUsuarioInput}
                      readOnly
                    />
                  </div>

                  <div className={styles.inputGroup}>
                    <label
                      className={styles.alterarUsuarioLabel}
                      htmlFor="user"
                    >
                      Usuário
                    </label>
                    <input
                      name="user"
                      value={editableUserData.user || ""}
                      onChange={handleInputChange}
                      className={styles.alterarUsuarioInput}
                      readOnly
                    />
                  </div>

                  <div className={styles.inputGroup}>
                    <label
                      className={styles.alterarUsuarioLabel}
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      name="email"
                      value={editableUserData.email || ""}
                      onChange={handleInputChange}
                      className={styles.alterarUsuarioInput}
                    />
                  </div>

                  <div className={styles.inputGroup}>
                    <label
                      className={styles.alterarUsuarioLabel}
                      htmlFor="cidade_sede"
                    >
                      Cidade Sede
                    </label>
                    <Select
                      name="cidade_sede"
                      value={editableUserData.cidade_sede || ""}
                      onChange={handleInputChange}
                      className={styles.dropdownSelect}
                    >
                      <MenuItem value="Balneário Camboriu">
                        Balneário Camboriu
                      </MenuItem>
                      <MenuItem value="Campinas">Campinas</MenuItem>
                      <MenuItem value="Campo Grande">Campo Grande</MenuItem>
                      <MenuItem value="Curitiba">Curitiba</MenuItem>
                      <MenuItem value="Londrina">Londrina</MenuItem>
                      <MenuItem value="Maringá">Maringá</MenuItem>
                      <MenuItem value="Presidente Prudente">
                        Presidente Prudente
                      </MenuItem>
                      <MenuItem value="Paranavaí">Paranavaí</MenuItem>
                      <MenuItem value="Teresina">Teresina</MenuItem>
                    </Select>
                  </div>

                  <div className={styles.inputGroup}>
                    <label
                      className={styles.alterarUsuarioLabel}
                      htmlFor="dt_nascimento"
                    >
                      Nascimento
                    </label>
                    <input
                      name="dt_nascimento"
                      type="date"
                      value={editableUserData.dt_nascimento || ""}
                      onChange={handleInputChange}
                      className={styles.alterarUsuarioInput}
                    />
                  </div>

                  <div className={styles.inputGroup}>
                    <label
                      className={styles.alterarUsuarioLabel}
                      htmlFor="telefone"
                    >
                      Telefone com DDD
                    </label>
                    <input
                      name="telefone"
                      type="tel"
                      value={editableUserData.telefone || ""}
                      onChange={handleInputChange}
                      placeholder="ex. 11999999999"
                      className={styles.alterarUsuarioInput}
                    />
                  </div>

                  <div className={`${styles.inputGroup} ${styles.fullWidth}`}>
                    <label className={styles.alterarUsuarioLabel}>Área</label>
                    <div className={styles.alterarUsuarioColumns}>
                      {[
                        "Assembleias",
                        "Cadastro",
                        "Cobrança",
                        "Financeiro",
                        "Juridico",
                        "RH",
                        "Seguros",
                      ].map((area) => (
                        <div
                          className={`${styles.alterarUsuarioCheckboxes} ${
                            editableUserData.area.includes(area)
                              ? styles.selected
                              : ""
                          }`}
                          key={area}
                          onClick={() => handleAreaClick(area)}
                        >
                          <input
                            type="checkbox"
                            name="area"
                            value={area}
                            checked={editableUserData.area.includes(area)}
                            onChange={handleInputChange}
                            readOnly
                          />
                          <label>{area}</label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className={styles.inputGroup}>
                    <label
                      className={styles.alterarUsuarioLabel}
                      htmlFor="funcao"
                    >
                      Função
                    </label>
                    <Select
                      name="funcao"
                      value={editableUserData.funcao || ""}
                      onChange={handleInputChange}
                      className={styles.dropdownSelect}
                      readOnly
                    >
                      <MenuItem value="admin">Admin</MenuItem>
                      <MenuItem value="Agente">Agente</MenuItem>
                      <MenuItem value="Gestor">Gestor</MenuItem>
                    </Select>
                  </div>

                  <div className={styles.inputGroup}>
                    <label
                      className={styles.alterarUsuarioLabel}
                      htmlFor="usuario_cidade"
                    >
                      Usuário Cidade?
                    </label>
                    <Select
                      name="usuario_cidade"
                      value={editableUserData.usuario_cidade || ""}
                      onChange={handleInputChange}
                      className={styles.dropdownSelect}
                      readOnly
                    >
                      <MenuItem value="Não">Não</MenuItem>
                      <MenuItem value="Sim">Sim</MenuItem>
                    </Select>
                  </div>

                  <div className={styles.inputGroup}>
                    <label
                      className={styles.alterarUsuarioLabel}
                      htmlFor="advogado_externo"
                    >
                      Advogado Externo?
                    </label>
                    <Select
                      name="advogado_externo"
                      value={editableUserData.advogado_externo || ""}
                      onChange={handleInputChange}
                      className={styles.dropdownSelect}
                      readOnly
                    >
                      <MenuItem value="Não">Não</MenuItem>
                      <MenuItem value="Sim">Sim</MenuItem>
                    </Select>
                  </div>

                  <div className={styles.inputGroup}>
                    <label
                      className={styles.alterarUsuarioLabel}
                      htmlFor="senha"
                    >
                      Senha
                    </label>
                    <div className={styles.userRegistrationBox}>
                      <div className={styles.userRegistrationInputBox}>
                        <input
                          type={showPassword ? "text" : "password"}
                          name="senha"
                          id="senha"
                          value={editableUserData.senha || ""}
                          onChange={handleInputChange}
                          className={styles.alterarUsuarioInput}
                        />
                        <button
                          type="button"
                          onClick={() => setShowPassword((prev) => !prev)}
                          className={styles.togglePasswordButton}
                        >
                          {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </button>
                      </div>
                      {errors.senha && (
                        <span className={styles.userRegistrationError}>
                          {errors.senha}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.alterarUsuarioDiv4}>
                <div className={styles.alterarUsuarioPhoto}>
                  <h3>Foto do Usuário</h3>
                  <div className={styles.alterarUsuarioPhotoPlaceholder}>
                    <img
                      src="placeholder.png"
                      alt=""
                      className={styles.alterarUsuarioPhotoImg}
                    />
                    <p className={styles.photoText}>Foto de Perfil</p>
                    <p>Em breve: Adicionar/Excluir Foto</p>
                  </div>
                </div>
              </div>

              <div className={styles.alterarUsuarioDiv5}>
                <PasswordChecker senha={editableUserData.senha} />
              </div>

              <div className={styles.alterarUsuarioDiv6}>
                <div className={styles.alterarUsuarioButtonGroup}>
                  <Button type="submit" className={styles.alterarUsuarioBotao}>
                    Atualizar
                  </Button>
                </div>
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default AlterarUsuario;
