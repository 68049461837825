import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import "./ChartTotalInadimplencia.css";

const ChartTotalInadimplencia = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const [chartData, setChartData] = useState({});
  const [topDevedores, setTopDevedores] = useState({
    comProcesso: [],
    semProcesso: [],
    semProcessoPorCondominio: [],
  });

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/dashboard-inadimplencia/inadimplencia-todos-condominios`
      );
      const dados = response.data;

      const agrupado = { comProcesso: 0, semProcesso: 0 };
      const somaPorUnidade = {};
      const somaPorCondominio = {};

      dados.forEach((item) => {
        const key =
          item.status_financeiro === "Juridico" ? "comProcesso" : "semProcesso";
        agrupado[key] += parseFloat(item.valorcorrigido || 0);

        const chaveUnidade = `${item.id_condominio_cond}-${item.id_unidade_uni}`;
        if (!somaPorUnidade[chaveUnidade]) {
          somaPorUnidade[chaveUnidade] = {
            ...item,
            valorcorrigido: parseFloat(item.valorcorrigido),
          };
        } else {
          somaPorUnidade[chaveUnidade].valorcorrigido += parseFloat(
            item.valorcorrigido
          );
        }

        if (key === "semProcesso") {
          const condominio = item.CONDOMINIO;
          if (!somaPorCondominio[condominio]) {
            somaPorCondominio[condominio] = {
              valorCorrigidoTotal: parseFloat(item.valorcorrigido || 0),
              unidades: new Set([chaveUnidade]), // Utiliza um Set para armazenar unidades únicas
            };
          } else {
            somaPorCondominio[condominio].valorCorrigidoTotal += parseFloat(
              item.valorcorrigido || 0
            );
            somaPorCondominio[condominio].unidades.add(chaveUnidade); // Adiciona a unidade ao Set, garantindo unicidade
          }
        }
      });

      setChartData({
        labels: ["Inadimplências"],
        datasets: [
          {
            label: "Sem Processo",
            data: [agrupado.semProcesso || 0],
            backgroundColor: "#164871",
            borderColor: "#ccc",
            borderWidth: 1,
            barPercentage: 0.5, // Ajusta a largura das barras
            categoryPercentage: 0.8, // Aumenta o espaço entre grupos de barras
          },
          {
            label: "Com Processo",
            data: [agrupado.comProcesso || 0],
            backgroundColor: "#0e93ffe1",
            borderColor: "#ccc",
            borderWidth: 1,
            barPercentage: 0.5, // Ajusta a largura das barras
            categoryPercentage: 0.8, // Aumenta o espaço entre grupos de barras
          },
        ],
      });

      const topSemProcesso = Object.values(somaPorUnidade)
        .filter((item) => item.status_financeiro !== "Juridico")
        .sort((a, b) => b.valorcorrigido - a.valorcorrigido)
        .slice(0, 1000);

      // Ao final do processamento, atualize a contagem de unidades para o tamanho do Set
      const topSemProcessoPorCondominio = Object.entries(somaPorCondominio)
        .map(([condominio, { valorCorrigidoTotal, unidades }]) => ({
          condominio,
          valorCorrigidoTotal,
          unidades: unidades.size, // Atualiza para a contagem de unidades únicas
        }))
        .sort((a, b) => b.valorCorrigidoTotal - a.valorCorrigidoTotal);

      setTopDevedores({
        comProcesso: [],
        semProcesso: topSemProcesso,
        semProcessoPorCondominio: topSemProcessoPorCondominio,
      });
    } catch (error) {
      console.error("Erro ao buscar dados: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!chartData.datasets || chartData.datasets.length === 0) {
    return (
      <div className="carregar-dashboard-inadimplencia">
        <CircularProgress />
        Aguarde, os dados estão sendo carregados.
      </div>
    );
  }

  const configChartInadimplencia = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 20, // Aumente este valor conforme necessário para evitar cortes
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "right",
        color: "#343434",
        font: {
          size: "10px",
          weight: "600",
        },
      },
      datalabels: {
        display: true,
        color: "#343434",
        font: {
          size: "14px",
          weight: "600",
        },
        anchor: "end",
        align: "top",
        formatter: (value) => {
          return (
            "R$ " +
            value.toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          );
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  return (
    <div className="dashboard-inadimplencia-container">
      <div className="chart-container">
        <h2 className="dashboard-title">Dashboard de Inadimplências</h2>
        {chartData.datasets.length > 0 && (
          <Bar data={chartData} options={configChartInadimplencia} />
        )}
      </div>
      <div className="group-tables">
        <div
          className="table-dashboard-inadimplencia-pessoas"
          style={{ width: "60%" }}
        >
          <h3 className="table-title">Sem Processo</h3>
          <div className="table-content">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell> {/* Coluna de índice adicionada */}
                  <TableCell>Condomínio</TableCell>
                  <TableCell>Unidade</TableCell>
                  <TableCell align="right">Proprietário</TableCell>
                  <TableCell align="right">Valor Corrigido</TableCell>
                  <TableCell align="right">Dias de Atraso</TableCell>{" "}
                  {/* Certifique-se que esta coluna esteja corretamente populada */}
                </TableRow>
              </TableHead>
              <TableBody>
                {topDevedores.semProcesso.map((row, index) => (
                  <TableRow
                    key={`${row.id_condominio_cond}-${row.id_unidade_uni}`}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell>{row.CONDOMINIO}</TableCell>
                    <TableCell>{row.id_unidade_uni}</TableCell>
                    <TableCell align="right">{row.nome}</TableCell>
                    <TableCell align="right">
                      {`R$ ${parseFloat(row.valorcorrigido).toLocaleString(
                        "pt-BR",
                        { style: "decimal", minimumFractionDigits: 2 }
                      )}`}
                    </TableCell>
                    <TableCell align="right">{row.diasatraso}</TableCell>{" "}
                    {/* Ajuste para exibir corretamente os dias de atraso */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>

        {/* Tabela Agrupada por Condomínio */}
        <div
          className="table-dashboard-inadimplencia-condo"
          style={{ width: "40%" }}
        >
          <h3 className="table-title">
            Condomínios Inadimplentes sem Processo
          </h3>
          <div className="table-content">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell> {/* Coluna de índice adicionada */}
                  <TableCell>Condomínio</TableCell>
                  <TableCell align="right">Total Valor Corrigido</TableCell>
                  <TableCell align="right">Unidades Inadimplentes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {topDevedores.semProcessoPorCondominio.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.condominio}
                    </TableCell>
                    <TableCell align="right">
                      {`R$ ${parseFloat(row.valorCorrigidoTotal).toLocaleString(
                        "pt-BR",
                        { style: "decimal", minimumFractionDigits: 2 }
                      )}`}
                    </TableCell>
                    <TableCell align="right">{row.unidades}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartTotalInadimplencia;
