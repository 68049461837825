/* ***********************************
Autor: Matheus Botelho
Data: 
Atividade: Topbar
Função: Criação da Topbar com modelo do Super Lógica
************************************ */
// GCOLLE - SSI 0070 - 24/10/24 - Baixar ATA pela notificação

import CustomIcon from "../../images/EurekaIcon.png";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserContext from "../UserContext";
import "./Topbar.css";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import CondominioPesquisa from "../Carteiras/CondominioPesquisa";
import Modal from "react-modal";
import NotificationsNoneRoundedIcon from "@mui/icons-material/NotificationsNoneRounded";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import Badge from "@mui/material/Badge";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import io from "socket.io-client"; //SSI 0013
import NewspaperRoundedIcon from "@mui/icons-material/NewspaperRounded"; //SSI 0018
import Tooltip from "@mui/material/Tooltip"; //SSI 0018
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"; //SSI
Modal.setAppElement("#root");

//SSI 0013

const CustomToast = ({ message }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={CustomIcon}
        alt="Icon"
        style={{ width: 50, height: 50, marginRight: 10 }}
      />
      {message}
    </div>
  );
};

const showCustomToast = (message, type = "info") => {
  const ToastComponent = ({ message }) => (
    <div style={{ display: "flex", alignItems: "center" }}>{message}</div>
  );

  if (type === "warning") {
    toast.warn(<ToastComponent message={message} />);
  } else {
    toast(<CustomToast message={message} />);
  }
};

//FIM DA ALTERAÇÃO

const Topbar = () => {
  const navigate = useNavigate();
  const { user, logout } = useContext(UserContext);
  const [notifications, setNotifications] = useState([]);
  const [userIdToSend, setUserIdToSend] = useState("");
  const [titleToSend, setTitleToSend] = useState("");
  const [messageToSend, setMessageToSend] = useState("");
  const [socket, setSocket] = useState(null);
  const [showNotifications, setShowNotifications] = useState(true);

  const API_URL = process.env.REACT_APP_API_URL;

  //SSI 0013

  useEffect(() => {
    console.log("Topbar component mounted");
    if (user) {
      console.log("User context value:", user);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const newSocket = io("https://euro-web-757c1b49a8fb.herokuapp.com/", {
        query: { userId: user.id_func, area: user.area },
      });

      const handleNewNotification = (notification) => {
        console.log("Notification received:", notification);
        setNotifications((prev) => [notification, ...prev]);

        if (notification.type === "condominium") {
          showCustomToast(
            "Atenção! Você tem uma nova notificação do seu condomínio!",
            "warning"
          );
        } else {
          showCustomToast("Você tem uma nova notificação!");
        }
      };

      newSocket.on("receiveNotification", handleNewNotification);

      setSocket(newSocket);

      // Desconectar e limpar ouvintes ao desmontar o componente
      return () => {
        newSocket.off("receiveNotification", handleNewNotification);
        newSocket.disconnect();
      };
    }
  }, [user]); // Dependências que, se alteradas, reiniciam o useEffect

  useEffect(() => {
    if (user && user.id_func) {
      fetchNotifications();
    }
  }, [user.id_func]); // Chama a função sempre que o usuário muda

  const fetchNotifications = async () => {
    if (!user || !user.id_func) {
      console.log("User ID is not available.");
      return;
    }

    const url = `${API_URL}/notificacoes/${user.id_func}`;
    console.log("Fetching notifications from:", url);
    try {
      const response = await fetch(url);
      console.log("Response status:", response.status);
      if (response.ok) {
        const data = await response.json();
        console.log("Notifications fetched:", data);
        setNotifications(data);
      } else {
        const errorText = await response.text();
        throw new Error("Failed to fetch notifications: " + errorText);
      }
    } catch (error) {
      console.error("Failed to fetch notifications", error);
    }
  };

  //FIM DA ALTERAÇÃO

  const sendInfoToServer = () => {
    console.log(
      "Sending notification:",
      userIdToSend,
      titleToSend,
      messageToSend
    );
    if (socket) {
      socket.emit("sendUserNotification", {
        userId: userIdToSend,
        title: titleToSend,
        message: messageToSend,
      });
    } else {
      console.error("Socket not initialized");
    }
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  //SSI 0018 - 27/05/24

  const handleUpdatesClick = () => {
    navigate("/ver-atualizacoes");
  };

  // FIM DA ALTERAÇÃO

  //SSI 0013

  const markAsRead = async (notificationId, lida) => {
    console.log(
      `Enviando pedido de marcação para a notificação ID: ${notificationId}, Novo status: ${
        lida ? "Lida" : "Não lida"
      }`
    );

    const response = await fetch(
      `${API_URL}/notificacoes/lida/${notificationId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ lida: lida ? 1 : 0 }),
      }
    );

    if (response.ok) {
      const updatedNotifications = notifications.map((n) =>
        n.id === notificationId ? { ...n, lida } : n
      );
      setNotifications(updatedNotifications);
      console.log("Notificação atualizada com sucesso:", updatedNotifications);
    } else {
      const errorText = await response.text();
      console.error("Falha ao atualizar o status da notificação:", errorText);
    }
  };

  const markAllAsRead = async () => {
    const unreadNotifications = notifications.filter((n) => !n.lida);
    const updatePromises = unreadNotifications.map((notification) =>
      fetch(`${API_URL}/notificacoes/lida/${notification.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ lida: 1 }),
      })
    );

    try {
      await Promise.all(updatePromises);

      const updatedNotifications = notifications.map((n) => ({
        ...n,
        lida: true,
      }));
      setNotifications(updatedNotifications);
      console.log("Todas notificações foram marcadas como lidas.");
    } catch (error) {
      console.error("Erro ao marcar todas as notificações como lidas", error);
    }
  };

  //FIM DA ALTERAÇÃO
  //SSI 0013

  //SSI 0070 - GCOLLE - Notificação com download ata publicada
  // INICIO
  const handleDownload = async (notificationId, nomeArquivo) => {
    try {
      // Solicita o `fileUrl` do backend usando apenas o ID da notificação
      const response = await fetch(
        `${API_URL}/assembleia/download-file-url/${notificationId}`
      );
      const data = await response.json();

      if (response.ok && data.success) {
        const link = document.createElement("a");
        link.href = data.fileUrl; // URL pré-assinada para download
        link.download = nomeArquivo || "downloaded_file";
        link.target = "_blank";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Erro ao obter o link do arquivo:", data.message);
      }
    } catch (error) {
      console.error("Erro ao baixar o arquivo:", error);
    }
  };
  // FIM

  return (
    <div className="topbar">
      <div className="user-section">
        <CondominioPesquisa />
        <span className="usuario">Olá, {user ? user.nome : "Visitante"}!</span>
        {user && (
          <>
            <Badge
              badgeContent={notifications.filter((n) => !n.lida).length}
              color="primary"
              className="icone-notificacoes"
              style={{ cursor: "pointer", position: "relative" }}
            >
              {notifications.some((n) => !n.lida) ? (
                <NotificationsRoundedIcon />
              ) : (
                <NotificationsNoneRoundedIcon />
              )}
              {showNotifications && (
                <div className="notifications-tooltip">
                  <button
                    className="marcar-todas-lidas"
                    onClick={markAllAsRead}
                  >
                    Marcar todas como lidas
                  </button>
                  <ul>
                    {notifications.map((notification) => {
                      console.log(notification);

                      return (
                        <li
                          key={notification.id}
                          className={
                            notification.lida
                              ? "notification read"
                              : "notification unread"
                          }
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {/* Conteúdo da notificação */}
                          <div
                            style={{ flex: 1, cursor: "pointer" }}
                            onClick={() =>
                              markAsRead(notification.id, !notification.lida)
                            }
                          >
                            <strong>{notification.titulo}:</strong>{" "}
                            {notification.mensagem}
                          </div>

                          {/* Verificar se há um link de anexo e mostrar o ícone de download */}
                          {notification.link_anexo && (
                            <CloudDownloadIcon
                              style={{
                                cursor: "pointer",
                                marginLeft: "8px",
                                color: "#007bff",
                              }}
                              onClick={() =>
                                handleDownload(
                                  notification.id, // Use `notification.id` em vez de `notification.link_anexo`
                                  notification.nome_arquivo
                                )
                              }
                            />
                          )}

                          {/* Indicador de não lida */}
                          {!notification.lida && (
                            <span
                              className="unread-indicator"
                              style={{ marginLeft: "8px", color: "red" }}
                            >
                              •
                            </span>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </Badge>

            {/* SSI 0018 - 27/05/24 */}
            <Tooltip title="Atualizações Eureka">
              <Badge badgeContent={"Novo"} color="secondary">
                <NewspaperRoundedIcon
                  className="topbar-updateIcon"
                  onClick={handleUpdatesClick}
                  style={{ cursor: "pointer" }}
                />
              </Badge>
            </Tooltip>
            {/* FIM DA ALTERAÇÃO */}

            <Link to="/" className="leave" onClick={handleLogout}>
              <div className="leave-icon">
                <LogoutRoundedIcon fontSize="small" fontWeight="bold" />
                <span>Sair</span>
              </div>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

//FIM DA ALTERAÇÃO

export default Topbar;
