/* ***********************************
Autor: Matheus Botelho
Data: 
Atividade: Sidebar
Função: Criação da Sidebar por hover com modelo do Super Lógica
************************************ */

// ------------ SSI 0055 - LCORREA - Corrigir Permissões de usuarios_cidade / advogados_externos - 05/09/2024 ------------ //

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import UserContext from "../UserContext";
import LibraryBooksRoundedIcon from "@mui/icons-material/LibraryBooksRounded";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import WindowRoundedIcon from "@mui/icons-material/WindowRounded";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import GavelRoundedIcon from "@mui/icons-material/GavelRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";
import MessageRoundedIcon from "@mui/icons-material/MessageRounded";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import "./Sidebar.css";

const Sidebar = () => {
	const { user } = useContext(UserContext);

	const areaIcons = {
		Geral: <WindowRoundedIcon className="sidebar-icon" />,
		Financeiro: <AttachMoneyRoundedIcon className="sidebar-icon" />,
		Juridico: <GavelRoundedIcon className="sidebar-icon" />,
		RH: <PeopleRoundedIcon className="sidebar-icon" />,
		Cadastro: <LibraryBooksRoundedIcon className="sidebar-icon" />,
		Informações: <InfoRoundedIcon className="sidebar-icon" />,
		Indicadores: <BarChartRoundedIcon className="sidebar-icon" />,
		Notificações: <MessageRoundedIcon className="sidebar-icon" />,
		Atualizações: <CampaignRoundedIcon className="sidebar-icon" />,
	};

	const areaSublinks = {
		Financeiro: [
			{ path: "/rateio", label: "Rateio" },
			{ path: "/fechamentomensal", label: "Fechamento Mensal" },
		],
		Cadastro: [{ path: "/alterartitularidade", label: "Alterar Titularidade" }],
		RH: [{ path: "/criar-salarios", label: "Remunerações e Imposto" }],
		Geral: [
			{
				label: "Agenda",
				sublinks: [
					{ path: "/agenda", label: "Ver Agenda" },
					(user.area?.includes("Assembleias") ||
						user.funcao === "Gestor" ||
						user.funcao === "admin") && {
						path: "/assembleia",
						label: "Assembleias",
					},
				].filter(Boolean),
			},
			{
				label: "Carteiras",
				sublinks:
					user.funcao === "admin" || user.funcao === "Gestor"
						? [
								{ path: "/editarcarteira", label: "Editar Carteira" },
								{ path: "/consultarcarteira", label: "Consultar Carteira" },
								{
									path: "/redirecionarcarteira",
									label: "Redirecionar Carteira",
								},
								{
									path: "/transferircarteira",
									label: "Transferência de Carteira",
								},
								{ path: "/carteirausuario", label: "Carteira do Usuário" },
								{ path: "/minhacarteira", label: "Minha Carteira" },
								{
									path: "/editarusuarioscidade",
									label: "Editar Usuários Cidade",
								},
						  ]
						: [
								{ path: "/consultarcarteira", label: "Consultar Carteira" },
								{ path: "/minhacarteira", label: "Minha Carteira" },
						  ],
			},
		],
		Informações:
			user.funcao === "Gestor" || user.funcao === "admin"
				? [
						{
							label: "Usuários",
							sublinks: [
								{ path: "/alterarusuario", label: "Alterar Usuário" },
								{ path: "/edituser", label: "Ausência" },
								{ path: "/editarausencia", label: "Editar Ausência" },
								{ path: "/cadastrousuario", label: "Cadastro de Usuário" },
								{ path: "/inativarusuario", label: "Inativar Usuário" },
							],
						},
				  ]
				: null,
		Juridico: [{ path: "/inadimplencia", label: "Inadimplência" }],
		Indicadores: [
			{ path: "/indicadorfinanceiro", label: "Financeiro" },
			{ path: "/dashboardinadimplencia", label: "Inadimplências" },
			{ path: "/kpisfinanceiro", label: "Lançamentos Financeiro" },
		],
		Notificações: [{ path: "/criar-notificacao", label: "Criar notificação" }],
		Atualizações: [
			{ path: "/criar-atualizacao", label: "Atualizações Eureka (TI)" },
			{ path: "/criar-evento", label: "Criar eventos" },
		],
	};

	const ALL_AREAS = [
		"Geral",
		"Financeiro",
		"Juridico",
		"RH",
		"Cadastro",
		"Informações",
		"Indicadores",
		"Notificações",
		"Atualizações",
	];

	const PERMISSIONS = {
		Diretoria: ALL_AREAS,
		Financeiro: ["Geral", "Financeiro", "Informações"],
		RH: ["Geral", "Informações", "RH"],
		Juridico: ["Geral", "Informações", "Juridico"],
		Cadastro: ["Geral", "Informações", "Cadastro"],
		Assembleias: ["Geral", "Informações", "Notificações"],
		Seguros: ["Geral", "Informações"],
		Gestor: ALL_AREAS,
		Cidade: ["Geral", "Juridico"],
		Cobrança: ["Geral", "Juridico"],
	};

	let allowedLinks = [];

	// ------------ INICIO DAS ALTERAÇÕES - LCORREA - 05/09/2024 ------------ //
	// LCORREA - Adicionar acesso ao "Ver Agenda" para usuario_cidade - 08/10/2024
	if (user && user.usuario_cidade === "Sim") {
		allowedLinks = ["Geral", "Juridico"];
		areaSublinks.Geral = [
			{
				label: "Agenda",
				sublinks: [{ path: "/agenda", label: "Ver Agenda" }], // Adicionando Ver Agenda para usuario_cidade
			},
			{
				label: "Carteiras",
				sublinks: [{ path: "/consultarcarteira", label: "Consultar Carteira" }],
			},
		];
	} else if (user && user.advogado_externo === "Sim") {
		allowedLinks = ["Juridico"];
	} else if (user && (user.funcao === "Gestor" || user.funcao === "admin")) {
		allowedLinks = ALL_AREAS;
	} else if (user && user.area) {
		user.area.split(",").forEach((areaName) => {
			if (PERMISSIONS[areaName]) {
				allowedLinks.push(...PERMISSIONS[areaName]);
			}
		});
	}
	// ------------ FIM DAS ALTERAÇÕES - LCORREA - 05/09/2024 ------------ //

	const uniqueAllowedLinks = Array.from(new Set(allowedLinks));

	// Se o usuário for advogado externo, remover as abas "Geral" e "Informações"
	if (user.advogado_externo === "Sim") {
		const filteredLinks = uniqueAllowedLinks.filter(
			(area) => area !== "Geral" && area !== "Informações"
		);
		uniqueAllowedLinks.splice(0, uniqueAllowedLinks.length, ...filteredLinks);
	}

	const links = [];
	uniqueAllowedLinks.forEach((area) => {
		links.push({ path: `/${area.toLowerCase()}`, label: area });
	});

	links.sort((a, b) => a.label.localeCompare(b.label));

	const atualizacoesIndex = links.findIndex(
		(link) => link.label === "Atualizações"
	);
	if (atualizacoesIndex !== -1) {
		const atualizacoesLink = links.splice(atualizacoesIndex, 1)[0];
		links.push(atualizacoesLink);
	}

	return (
		<div className="sidebar">
			<div className="logo-details">
				<Link to="/dashboard">
					<img src="/EurekaLogo.jpeg" alt="Logo Eureka" />
				</Link>
			</div>
			<ul className="nav-links">
				{links.map((link) => (
					<li key={link.label}>
						<div className="icon-link">
							{link.label === "Juridico" ? (
								<div className="icon-link juridico-disabled">
									<div>{areaIcons[link.label]}</div>
									<div>
										<span className="link-name">{link.label}</span>
									</div>
								</div>
							) : (
								<Link to={link.path} className="icon-link">
									<div>{areaIcons[link.label]}</div>
									<div>
										<span className="link-name">{link.label}</span>
									</div>
								</Link>
							)}
						</div>
						<ul className="submenu">
							{areaSublinks[link.label]?.map(
								(sublink) =>
									sublink && (
										<li key={sublink.label}>
											<Link to={sublink.path}>{sublink.label}</Link>
											{sublink.sublinks && (
												<ul className="submenu-inner">
													{sublink.sublinks.map((subsublink) => (
														<li key={subsublink.label}>
															<Link to={subsublink.path}>
																{subsublink.label}
															</Link>
														</li>
													))}
												</ul>
											)}
										</li>
									)
							)}
						</ul>
					</li>
				))}
				<div className="help">
					<Link to="https://euroadm.movidesk.com/kb">
						<div>
							<QuestionMarkRoundedIcon className="sidebar-icon" />
						</div>
						<div>
							<span>Precisa de ajuda</span>
						</div>
					</Link>
				</div>
			</ul>
		</div>
	);
};

export default Sidebar;
