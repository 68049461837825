// ------------ SSI 0055 - LCORREA - Corrigir Permissões de usuarios_cidade / advogados_externos - 05/09/2024 ------------ //
import React, { useState, useEffect, useContext } from "react";
import UserContext from "../UserContext";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  CircularProgress,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  Link,
  IconButton,
  DialogActions,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CloseIcon from "@mui/icons-material/Close";
import "./Inadimplencia.css";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded"; //SSI 0064 - MATHEUS BOTELHO - 15/10/24
import { saveAs } from "file-saver"; //SSI 0064 - MATHEUS BOTELHO - 15/10/24
import axios from "axios"; //SSI 0064 - MATHEUS BOTELHO - 15/10/24
import { Tooltip } from "@mui/material"; //SSI 0064 - MATHEUS BOTELHO - 15/10/24
import { toast } from "react-toastify"; //SSI 0064 - MATHEUS BOTELHO - 15/10/24
import "react-toastify/dist/ReactToastify.css"; //SSI 0064 - MATHEUS BOTELHO - 15/10/24

export default function Inadimplencia() {
  const { user } = useContext(UserContext);

  const [condominios, setCondominios] = useState([]);
  const [selectedCondominio, setSelectedCondominio] = useState(null);
  const [loadingCobrancas, setLoadingCobrancas] = useState(false);
  const [cobrancasAgrupadas, setCobrancasAgrupadas] = useState([]);
  const navigate = useNavigate();
  const [acordos, setAcordos] = useState([]);
  const API_URL = process.env.REACT_APP_API_URL; //LCORREA - Usando variável de ambiente

  useEffect(() => {
    console.log("useEffect para buscar condomínios iniciado");
    if (user) {
      console.log("Usuário detectado:", user);

      const isUsuarioCidade = user.usuario_cidade === "Sim";
      const isAdvogadoExterno = user.advogado_externo === "Sim";

      let fetchURL = `${API_URL}/juridico/buscar-condominios`;

      if (isUsuarioCidade && !isAdvogadoExterno) {
        // Nova URL que busca tanto os condomínios da cidade quanto os restritos
        fetchURL = `${API_URL}/juridico/usuario/condominios-cidade-restritos/${user.id_func}`;
      } else if (isAdvogadoExterno) {
        fetchURL = `${API_URL}/juridico/usuario-condominios/${user.id_func}/nao`;
      }

      console.log(`URL para fetch: ${fetchURL}`);

      fetch(fetchURL)
        .then((response) => {
          console.log("Status da resposta:", response.status);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log("Dados dos condomínios recebidos:", data);

          // Ordenar os dados pelo campo 'chave' (id_cond) de forma crescente
          const sortedData = data.sort((a, b) => a.chave - b.chave);

          // Definir os condomínios ordenados no estado
          setCondominios(sortedData);
        })
        .catch((error) => {
          console.error(`Erro ao buscar condomínios:`, error);
        });
    } else {
      console.log("Usuário não detectado (user é null ou undefined)");
    }
  }, [user]);

  const handleEfetuarAcordoClick = (cobranca) => {
    let idCondominio;

    // Adicionando log para verificar selectedCondominio.chave e selectedCondominio.id_cond
    console.log("selectedCondominio.chave:", selectedCondominio?.chave);
    console.log("selectedCondominio.id_cond:", selectedCondominio?.id_cond);

    // Verifica se o usuário é da cidade ou advogado externo e pega o ID correto
    if (user.usuario_cidade === "Sim") {
      idCondominio = selectedCondominio.chave; // Usando 'chave' como identificador
    } else {
      idCondominio = selectedCondominio.chave; // Usando 'chave' para advogados externos também
    }

    // Verifica se o idCondominio está correto
    console.log("ID do condomínio selecionado para o acordo:", idCondominio);
    console.log("Dados da cobrança selecionada:", cobranca);

    if (idCondominio) {
      const params = new URLSearchParams({
        unidade: cobranca.unidade, // Unidade da cobrança
        idUnidade: cobranca.idUnidade, // ID da unidade
        idsCobrancas: cobranca.idsCobrancas.join(","), // IDs das cobranças, separados por vírgula
        totalDevedor: cobranca.total.toFixed(2), // Total devido
        idCondominio: idCondominio, // ID do condomínio selecionado
      });

      // Logando a URL para verificar se os parâmetros estão sendo passados corretamente
      console.log("URL construída:", `/efetuar-acordo?${params.toString()}`);

      // Navegando para a página do processo de acordo
      navigate(`/efetuar-acordo?${params.toString()}`);
    } else {
      console.warn("ID do condomínio está indefinido.");
    }
  };

  useEffect(() => {
    if (selectedCondominio) {
      const idCondominio = selectedCondominio.chave; //LCORREA - Correção da variável

      console.log("ID do condomínio selecionado:", idCondominio);

      if (idCondominio) {
        setLoadingCobrancas(true);

        fetch(`${API_URL}/juridico/buscar-cobrancas/${idCondominio}`) //LCORREA
          .then((response) => {
            console.log(
              `Status da resposta para buscar-cobrancas: ${response.status}`
            );
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => {
            console.log("Dados das cobranças recebidos:", data);
            processarCobrancas(data);
            setLoadingCobrancas(false);
          })
          .catch((error) => {
            console.error("Erro ao buscar cobranças:", error);
            setLoadingCobrancas(false);
          });

        buscarAcordos(idCondominio);
      } else {
        console.warn("ID do condomínio está indefinido.");
      }
    }
  }, [selectedCondominio, user.usuario_cidade]);

  const buscarAcordos = async (idCondominio) => {
    try {
      if (!idCondominio) {
        console.warn(
          "ID do condomínio não foi definido ao tentar buscar acordos."
        );
        return;
      }

      console.log(
        `Iniciando a busca de acordos para o condomínio com ID: ${idCondominio}`
      );

      const response = await fetch(
        `${API_URL}/juridico/buscar-acordos/${idCondominio}` //LCORREA
      );

      console.log(
        `Status da resposta HTTP para buscar-acordos: ${response.status}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const dadosAcordos = await response.json();
      console.log("Dados dos acordos recebidos:", dadosAcordos);

      const acordosFormatados = dadosAcordos.map((acordo) => ({
        ...acordo,
        total_acordo: parseFloat(acordo.total_acordo), // Garante que total_acordo seja um número
      }));
      setAcordos(acordosFormatados);
    } catch (error) {
      console.error("Erro ao buscar acordos:", error);
    }
  };

  // Definindo a função processarCobrancas //LCORREA
  const processarCobrancas = (dados) => {
    const agrupadas = {};

    dados.forEach((item) => {
      if (
        !item.recebimento ||
        item.recebimento.length === 0 ||
        !item.st_unidade_uni
      ) {
        return;
      }

      // Identifica se é condomínio horizontal ou vertical
      const isHorizontal =
        item.st_unidade_uni.includes("LT") && item.st_bloco_uni.includes("QD");
      const unidadeFormatada = isHorizontal
        ? `${item.st_unidade_uni} ${item.st_bloco_uni}`
        : `${item.st_bloco_uni} ${item.st_unidade_uni}`;

      if (!agrupadas[unidadeFormatada]) {
        agrupadas[unidadeFormatada] = {
          idUnidade: item.id_unidade_uni,
          cobrancas: [],
          total: 0,
          numCobrancas: 0,
        };
      }

      agrupadas[unidadeFormatada].numCobrancas = item.recebimento.length;
      item.recebimento.forEach((cobranca) => {
        agrupadas[unidadeFormatada].cobrancas.push(
          cobranca.id_recebimento_recb
        );
        agrupadas[unidadeFormatada].total += parseFloat(cobranca.vl_total_recb);
      });
    });

    setCobrancasAgrupadas(
      Object.entries(agrupadas).map(([unidade, dados]) => ({
        unidade,
        idUnidade: dados.idUnidade,
        idsCobrancas: dados.cobrancas,
        total: dados.total,
        numCobrancas: dados.numCobrancas,
      }))
    );
  };

  //SSI 0064 - MATHEUS BOTELHO - 15/10/24 - INICIO

  const gerarRelatorioInadimplencia = async (condominioId) => {
    // Exibe o toast de loading
    const toastId = toast.loading("Gerando relatório de inadimplência...");

    // Obter a data atual e formatar como dia-mes-ano
    const dataAtual = new Date();
    const dia = String(dataAtual.getDate()).padStart(2, "0"); // Garante que o dia tenha 2 dígitos
    const mes = String(dataAtual.getMonth() + 1).padStart(2, "0"); // Meses em JS começam do 0, por isso +1
    const ano = dataAtual.getFullYear();

    try {
      // Faz a requisição para a API que gera o relatório, passando o idCondominio como parâmetro
      const response = await axios.get(`${API_URL}/juridico/gerar-relatorio`, {
        params: {
          idCondominio: condominioId,
        },
        responseType: "blob", // Definir o tipo de resposta como blob para lidar com arquivos
      });

      // Verifica o status da resposta
      if (response.status === 200) {
        // Formata o nome do arquivo com a data atual
        const fileName = `Relatorio_Inadimplencia_Euro_${condominioId}_${dia}-${mes}-${ano}.pdf`;

        // Utiliza a função saveAs para salvar o arquivo PDF
        saveAs(response.data, fileName);

        // Atualiza o toast para sucesso
        toast.update(toastId, {
          render: "Relatório gerado e baixado com sucesso!",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
      } else {
        // Atualiza o toast para erro
        toast.update(toastId, {
          render: "Falha ao gerar o relatório de inadimplência.",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        console.error("Falha ao gerar o relatório de inadimplência:", response);
      }
    } catch (error) {
      // Atualiza o toast para erro
      toast.update(toastId, {
        render: "Erro ao gerar o relatório de inadimplência.",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
      console.error("Erro ao gerar o relatório de inadimplência:", error);
    }
  };

  //SSI 0064 - MATHEUS BOTELHO - 15/10/24 - FIM

  return (
    <div className="inadimplencia-container">
      <div className="inadimplencia-content">
        <div className="inadimplencia-title">Acordos</div>

        <div className="inadimplencia-tudo">
          <Autocomplete
            className="selecionar-condominio"
            options={condominios}
            getOptionLabel={(option) =>
              `${option.razao} - ${option.chave} - ${option.cidade}`
            } // Usando os campos renomeados no backend
            onChange={(event, newValue) => setSelectedCondominio(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Selecione um condomínio" />
            )}
          />

          {/* SSI 0064 - MATHEUS BOTELHO - 15/10/24 - INICIO */}

          {selectedCondominio && (
            <div className="gerar-relatorio-container">
              {/* Botão para gerar relatório de inadimplência com Tooltip */}
              <Tooltip title="Gerar Relatório de Inadimplência">
                <DescriptionRoundedIcon
                  style={{
                    cursor: "pointer",
                    fontSize: 30,
                    color: "#0e94ff",
                    marginLeft: "auto",
                  }}
                  onClick={() =>
                    gerarRelatorioInadimplencia(selectedCondominio.chave)
                  }
                />
              </Tooltip>
            </div>
          )}

          {/* SSI 0064 - MATHEUS BOTELHO - 15/10/24 - FIM */}

          {selectedCondominio &&
            (loadingCobrancas ? (
              <div className="inadimplencia-cobrancas-condominio">
                <CircularProgress size={60} />
              </div>
            ) : (
              <TableContainer component={Paper}>
                <Table aria-label="tabela de cobranças">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Selecionar</TableCell>
                      <TableCell align="center">Detalhado</TableCell>
                      <TableCell align="center">Unidade</TableCell>
                      <TableCell align="center">Número de Cobranças</TableCell>
                      <TableCell align="center">
                        Principal (Não Atualizado)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cobrancasAgrupadas.map((cobranca, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">
                          {/* Adicione um checkbox ou outro controle de seleção, se necessário */}
                        </TableCell>
                        <TableCell align="center">
                          <button
                            className="inadimplencia-tabela-botao"
                            onClick={() => handleEfetuarAcordoClick(cobranca)}
                          >
                            Ver detalhes
                          </button>
                        </TableCell>
                        <TableCell align="center">{cobranca.unidade}</TableCell>
                        <TableCell align="center">
                          {cobranca.numCobrancas}
                        </TableCell>
                        <TableCell align="center">
                          {cobranca.total.toFixed(2)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ))}
        </div>
      </div>
    </div>
  );
}
